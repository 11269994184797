const LocalizationMap = {
  'en-US': 'American English',
  'en-AU': 'Australian English',
  'pt-BR': 'Português do Brasil',
  'en-GB': 'British English',
  'en-CA': 'Canadian English',
  'fr-CA': 'Français canadien',
  'cs-CZ': 'Čeština',
  'nl-NL': 'Nederlands',
  'tl-PH': 'Tagalog',
  'fr-FR': 'français',
  'de-DE': 'Deutsch',
  'en-IN': 'Indian English',
  'it-IT': 'Italiano',
  'ja-JP': '日本語',
  'ko-KR': '한국어',
  'es-MX': 'Español mexicano',
  'pl-PL': 'polski',
  'pt-PT': 'Português',
  'ru-RU': 'Русский',
  'zh-CN': '简体中文',
  'es-ES': 'Español',
  'sv-SE': 'Svenska',
  'zh-TW': '繁體中文',
  'tr-TR': 'Türkçe'
};

const Locales = Object.keys(LocalizationMap);

module.exports = {
  LocalizationMap,
  Locales
};
