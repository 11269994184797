import React from 'react';

import { useAppContext } from '../context/appContext';
import AuthenticatedApp from './authenticated-app';
import UnauthenticatedApp from './unauthenticated-app';
import ErrorPage from './error-page/ErrorPage';

const App = () => {
  const { user, config } = useAppContext();

  return user ? config ? <AuthenticatedApp user={user} appConfig={config} /> : <ErrorPage /> : <UnauthenticatedApp />;
};

export default App;
