import React from 'react';
import { Button } from '@amzn/awsui-components-react-v3';
import { useTranslation } from 'react-i18next';

const ErrorPage: React.FC = () => {
  const handleTryAgain = () => {
    window.location.href = window.location.origin;
  };
  const { t } = useTranslation('common');
  return (
    <div className="error-boundry awsui-util-container">
      <div className="awsui-util-container-header">
        <h2>{t('errorPage_oops')}</h2>
      </div>

      <div className="awsui-util-mv-m">
        {t('errorPage_something_went_wrong_at_our_end')}
        <br />
        {t('errorPage_do_not_worry_its_not_you_its_us')}
        <br />
        {t('errorPage_sorry_about_that')}
      </div>

      <div className="awsui-util-container-footer">
        <Button variant="primary" onClick={handleTryAgain}>
          {t('errorPage_try_again')}
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
