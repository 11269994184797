import { AppConfig } from '../../context/authService';
import Cookies from 'universal-cookie';
import { EXPIRATION_DURATION_IN_MILLIS } from '../../context/authHelpers';

export const SUPPORTED_REGIONS = ['NA', 'EU', 'FE', 'CN'];
/**
 * Class exposes functions to handle region retrieval and manipulation.
 */
export class AuthRegionAccessor {
  static DEFAULT_REGION = 'NA';

  configuration: AppConfig;

  constructor(configuration: AppConfig) {
    this.configuration = configuration;
  }

  /**
   * Saves region name in the chosen store.
   */
  saveRegion(region: string) {
    const cookies = new Cookies();
    const expirationDate = new Date();
    const cookieDomain = '.' + window.location.host;

    expirationDate.setTime(expirationDate.getTime() + EXPIRATION_DURATION_IN_MILLIS);

    cookies.set(this.configuration.AUTH_REGION_COOKIE_NAME, region, {
      path: '/',
      secure: true,
      domain: cookieDomain,
      expires: expirationDate
    });
  }

  /**
   * Gets saved region name from the store.
   */
  getRegion() {
    const cookies = new Cookies();
    return cookies.get(this.configuration.AUTH_REGION_COOKIE_NAME);
  }
}
