import styled from 'styled-components';
import * as awsui from '@amzn/awsui-design-tokens';

export const StyledLoginPage = styled.div`
  .login__header {
    background-color: ${awsui.colorBackgroundHomeHeader};
    padding: 4rem 0 3rem 0;
    width: 100%;
  }

  .login__header-title,
  .login__header-title p {
    font-weight: lighter;
  }

  .login__header-title {
    color: ${awsui.colorTextHomeHeaderDefault};
  }

  .login__header-title p {
    color: ${awsui.colorTextHomeHeaderSecondary};
  }

  .login__column-parent {
    text-align: center;
    min-height: 25rem;
  }

  .login__column-child {
    margin: 1rem auto 1rem auto;
  }
`;
