import Cookies from 'universal-cookie';

const RP_TOKEN_KEY_NAME = 'rp_token';
export const EXPIRATION_DURATION_IN_MILLIS = 12 * 60 * 60 * 1000; // 12 hours

export function handleTokens(cookieName: string) {
  const hashFragment = window.location.hash.substr(1);
  const rpTokenIndex = hashFragment.indexOf(RP_TOKEN_KEY_NAME);

  const indexOfEqual = hashFragment.indexOf('=');
  if (rpTokenIndex < 0 || indexOfEqual < 0 || indexOfEqual === hashFragment.length - 1) {
    return;
  }

  const rpToken = hashFragment.substr(indexOfEqual + 1);
  const cookieDomain = '.' + window.location.host;
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + EXPIRATION_DURATION_IN_MILLIS);

  const cookies = new Cookies();
  cookies.set(cookieName, rpToken, {
    path: '/',
    secure: true,
    domain: cookieDomain,
    expires: expirationDate
  });
}

export function invalidateSession(cookieNames: string[]) {
  const cookies = new Cookies();

  const cookieDomain = '.' + window.location.host;

  cookieNames.forEach(cookieName => {
    cookies.remove(cookieName, {
      path: '/',
      domain: cookieDomain
    });
  });
}
