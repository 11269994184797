import React from 'react';

import { useAppContext } from '../context/appContext';
import Header from './header/Header';
import Login from './login/Login';

const UnauthenticatedApp = () => {
  const { config } = useAppContext();

  return (
    <div>
      <Header showOptions={false} />
      <Login configuration={config} />
    </div>
  );
};

export default UnauthenticatedApp;
