import { History } from 'history';
import { AuthRegionAccessor } from '../../auth-region-selector/auth-region-accessor';
import { getQueryParameterOrDefault, setQueryParameter } from '../../../utilities/url-helper';

export const SUPPORTED_REGIONS = ['NA', 'EU', 'FE', 'CN'];

/**
 * Class exposes functions to handle region retrieval and manipulation.
 */
export class RegionAccessor {
  static REGION_KEY = 'vibeRegion';

  history: History;
  defaultRegion: string;

  constructor(history: History, authRegionAccessor: AuthRegionAccessor) {
    this.history = history;

    // The default region needs to be the region of authentication
    this.defaultRegion = authRegionAccessor.getRegion();

    // TODO: Remove below condition for CN after it is launched for Vibe
    if (!this.defaultRegion || this.defaultRegion === 'CN') {
      this.defaultRegion = AuthRegionAccessor.DEFAULT_REGION;
    }
  }

  /**
   * Saves region name in the chosen store.
   */
  saveRegion(region: string) {
    const { pathname } = this.history.location;
    const newPath = pathname + setQueryParameter(RegionAccessor.REGION_KEY, region);

    this.history.replace(newPath, { isRegionChanged: true });
  }

  /**
   * Gets saved region name from the store.
   */
  getRegion() {
    return getQueryParameterOrDefault(RegionAccessor.REGION_KEY, this.defaultRegion);
  }
}
