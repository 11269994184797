import styled from 'styled-components';
import * as awsui from '@amzn/awsui-design-tokens';

export const StyledFooter = styled.footer`
  margin: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 30px;
  background: ${awsui.colorBackgroundHomeHeader};
  color: white;
  text-align: center;
  font-size: x-small;
`;
