import i18n from 'i18next';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import logger from './utilities/logger';
import { Locales } from './constants/localization-data';

const hashValues = require('./hashValuesForI18n.json');

function getLoadPath(lng: string, ns: string) {
  if (typeof hashValues[ns] === 'undefined') {
    return `/static/i18n/${ns}/${lng}.emptyHash.json`;
  }

  const hashValue = hashValues[ns][lng];
  return `/static/i18n/${ns}/${lng}.${hashValue}.json`;
}

i18n
  .use(HttpApi)
  .use(ICU)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      defaultNS: 'common',
      fallbackNS: 'common',
      ns: 'common',
      supportedLngs: Locales,
      debug: process.env.NODE_ENV !== 'production',
      fallbackLng: 'en-US',
      partialBundledLanguages: true,
      load: 'currentOnly',
      backend: {
        loadPath: (languages: string[], namespaces: string[]) => {
          return getLoadPath(languages[0], namespaces[0]);
        }
      },

      detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'cookie', 'querystring', 'navigator'],

        lookupLocalStorage: 'vibelang',
        lookupQuerystring: 'vibelang',
        lookupCookie: 'vibelang',

        caches: ['localStorage', 'cookie']
      },
      react: {
        // ensure all required language files are loaded before rendering
        wait: true
      },
      interpolation: {
        escapeValue: false // not needed for react as it escapes by default
      }
    },
    (err, t) => {
      if (err) logger.error('Error initializing i18next instance.', err);
    }
  );

export default i18n;
