import React from 'react';
import { ColumnLayout, Icon, Popover } from '@amzn/awsui-components-react-v3';
import { useTranslation } from 'react-i18next';
import { LanguageButton, LanguageButtonContent } from './LanguageButton';
import { LanguageSelectorIcon } from '../styles';
import { Locales } from '../../../constants/localization-data';

const LanguageSelectorContent = () => {
  const locales = Locales.map((locale: string) => locale);

  const halfLength = Math.ceil(locales.length / 2);
  const localesFirstHalf = locales.splice(0, halfLength);
  const localesSecondHalf = locales.splice(-halfLength);

  return (
    <ColumnLayout columns={2}>
      <div>
        {localesFirstHalf.map((locale: string) => (
          <LanguageButton locale={locale} key={locale} />
        ))}
      </div>
      <div>
        {localesSecondHalf.map((locale: string) => (
          <LanguageButton locale={locale} key={locale} />
        ))}
      </div>
    </ColumnLayout>
  );
};

export const LanguageSelector = () => {
  const { i18n } = useTranslation('common');

  return (
    <Popover
      dismissButton={false}
      fixedWidth={true}
      position="bottom"
      size="large"
      triggerType="text"
      content={<LanguageSelectorContent />}
    >
      <LanguageButtonContent locale={i18n.language} blackColor={false} />
      <LanguageSelectorIcon>
        <Icon name="caret-down-filled" size="normal" />
      </LanguageSelectorIcon>
    </Popover>
  );
};
