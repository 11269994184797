import React from 'react';
import { Button } from '@amzn/awsui-components-react-v3';
import { useHistory } from 'react-router-dom';

import { HOME_PAGE } from '../../constants/page-paths';
import { useTranslation } from 'react-i18next';

const AccessDenied: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation('common');
  const homeClickHandler = () => history.push(HOME_PAGE);

  return (
    <div className="awsui-util-container">
      <div className="awsui-util-container-header">
        <h2>{t('accessDeniedPage_access_denied')}</h2>
      </div>

      <div className="awsui-util-mv-m">
        {t('accessDeniedPage_do_not_have_access_to_resources')}
        <br />
        {t('accessDeniedPage_try_again_later')}
      </div>

      <div className="awsui-util-container-footer">
        <Button variant="primary" onClick={homeClickHandler}>
          {t('accessDeniedPage_go_home')}
        </Button>
      </div>
    </div>
  );
};

export default AccessDenied;
