import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledFooter } from './styles';

const Footer: React.FC = () => {
  const { t } = useTranslation('common');
  const year = new Date().getFullYear();
  return (
    <StyledFooter id="footer">
      © {year}, {t('vibe_footer')}
    </StyledFooter>
  );
};

export default Footer;
