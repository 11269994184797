import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@amzn/awsui-components-react-v3/polaris/button';
import { LanguageSelectorText } from '../styles';
import { LocalizationMap } from '../../../constants/localization-data';

const localizationMap: Record<string, string> = LocalizationMap;

interface LanguageButtonContentProps {
  locale: string;
  blackColor: boolean;
}

export const LanguageButtonContent: React.FC<LanguageButtonContentProps> = ({ locale, blackColor }) => {
  const countryCode = locale.split('-')[1];
  return (
    <>
      <div>
        <img src={`/img/flags/${countryCode}.png`} height="15" width="20" alt="" />
      </div>
      <LanguageSelectorText blackColor={blackColor}> {localizationMap[locale]} </LanguageSelectorText>
    </>
  );
};

export const LanguageButton: React.FC<{ locale: string }> = ({ locale }) => {
  const { i18n } = useTranslation('common');

  const changeLanguage = () => {
    i18n.changeLanguage(locale, (err, t) => {
      if (err) {
        console.error('error occured while changing language', err);
      }
    });
  };

  return (
    <Button variant="link" className="awsui-util-m-n" onClick={changeLanguage} data-testId="languageButton">
      <LanguageButtonContent locale={locale} blackColor={true} />
    </Button>
  );
};
