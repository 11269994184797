/**
 * Get the current domain without sub-paths.
 */
export const getCurrentDomain = () => {
  return window.location.protocol + '//' + window.location.host;
};

/**
 * Get the current href.
 */
export const getCurrentHref = () => {
  return window.location.href;
};

/**
 * Get the current URL object.
 */
export const getCurrentUrl = () => {
  return new URL(getCurrentHref());
};

/**
 * Retrieves the search query value from the current url
 * or defaults it if not present.
 */
export const getQueryParameterOrDefault = (urlQueryKey: string, defaultValue: string) => {
  let url = getCurrentUrl();
  let value = url.searchParams.get(urlQueryKey);
  return value == null ? defaultValue : value;
};

/**
 * Sets the new value in search query for the given key.
 */
export const setQueryParameter = (urlQueryKey: string, value: string) => {
  let url = getCurrentUrl();
  url.searchParams.set(urlQueryKey, value);
  return url.search;
};
