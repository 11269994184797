import Auth from '@aws-amplify/auth';
import Analytics from '@aws-amplify/analytics';
import { getLCP, getFID, getCLS, getFCP, getTTFB, Metric } from 'web-vitals';

import { AmplifyConfig } from '../context/authService';

export function configureAnalytics(amplifyConfig: AmplifyConfig, userId: String) {
  const { height, width } = window.screen;

  Auth.configure({
    Auth: {
      identityPoolId: amplifyConfig.cognitoIdentityPoolId,
      region: amplifyConfig.region
    }
  });
  Analytics.configure({
    AWSPinpoint: {
      appId: amplifyConfig.pinpointAppId,
      region: amplifyConfig.region,
      endpoint: {
        attributes: {
          height: [`${height}`],
          width: [`${width}`],
          resolution: [`${width}x${height}`]
        },
        userId,
        userAttributes: {
          id: [userId]
        }
      }
    }
  });

  Analytics.autoTrack('session', { enable: true });
  Analytics.autoTrack('pageView', { enable: true, type: 'SPA' });
  Analytics.autoTrack('event', { enable: true });
  reportWebVitals();
}

function reportWebVitals() {
  const sendToAnalytics = (metric: Metric) => {
    Analytics.record({
      name: `vibe_webvitals_${metric.name}`,
      metrics: {
        value: metric.value,
        delta: metric.delta
      }
    });
  };

  getLCP(sendToAnalytics);
  getFID(sendToAnalytics);
  getCLS(sendToAnalytics);
  getFCP(sendToAnalytics);
  getTTFB(sendToAnalytics);
}
