import logger from '../utilities/logger';

export interface AmplifyConfig {
  region: string;
  pinpointAppId: string;
  cognitoIdentityPoolId: string;
}

export interface AppConfig {
  AUTHENTICATE_API_URL: string;
  GET_USER_IDENTITY_API_URL: string;
  CN_COOKIE_SET_API_URL: string;
  LANDING_PAGE_COOKIE_NAME: string;
  BROWSER_SESSION_COOKIE_NAME: string;
  AUTH_REGION_COOKIE_NAME: string;
  amplify: AmplifyConfig;
}

interface KeyValue {
  key: string;
  value: string;
}

export const fetchConfig = async () => {
  try {
    const response = await fetch('/config/config.json');
    if (!response.ok) {
      throw Error(response.statusText);
    }

    const config = await response.json();
    return config as AppConfig;
  } catch (err) {
    logger.error('FetchConfig', err);
    throw err;
  }
};

export const fetchUserData = async (apiUrl: string) => {
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      credentials: 'include'
    });
    if (!response.ok) {
      throw Error(response.statusText);
    }

    const data = await response.json();
    if (!data.userIdentity) {
      throw Error('User is not authenticated');
    }

    return data.userIdentity as string;
  } catch (err) {
    logger.error('FetchUserIdentity', err);
    throw err;
  }
};

export const setCnCookieCall = async (appConfig: AppConfig) => {
  try {
    const cookies: KeyValue[] = document.cookie
      .split(';')
      .map((cookie: string) => {
        return {
          key: cookie.split('=')[0].trim(),
          value: cookie.split('=')[1].trim()
        };
      })
      .filter(
        (cookie: KeyValue) =>
          cookie.key === appConfig.BROWSER_SESSION_COOKIE_NAME ||
          cookie.key === appConfig.LANDING_PAGE_COOKIE_NAME ||
          cookie.key === appConfig.AUTH_REGION_COOKIE_NAME
      );

    return Promise.all(
      (
        await Promise.all(
          cookies.map((cookie: KeyValue) =>
            fetch(appConfig.CN_COOKIE_SET_API_URL, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                key: cookie.key,
                value: cookie.value
              })
            })
          )
        )
      ).map(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
    );
  } catch (err) {
    logger.error('SetCnCookie error', err);
    throw err;
  }
};
