import * as React from 'react';
import { ButtonDropdown, ButtonDropdownProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react-v3';
import { SUPPORTED_REGIONS, AuthRegionAccessor } from './auth-region-accessor';

/**
 * Transforms the supported regions list to drop down input.
 */
const toButtonDropdownItems = (supportedRegionsList: string[]): ButtonDropdownProps.Items => {
  return supportedRegionsList.map(region => {
    return { text: region, id: region, disabled: false };
  });
};

const AUTH_REGION_DROPDOWN_ITEMS = toButtonDropdownItems(SUPPORTED_REGIONS);

interface AuthRegionSelectorProps {
  authRegionAccessor: AuthRegionAccessor;
}
/**
 * This component exposes a dropdown interface to select and save the region.
 */
const AuthRegionSelector: React.FC<AuthRegionSelectorProps> = ({ authRegionAccessor }) => {
  const [region, setRegion] = React.useState(() => {
    let regionInStore = authRegionAccessor.getRegion();
    if (!regionInStore || !SUPPORTED_REGIONS.includes(regionInStore)) {
      regionInStore = AuthRegionAccessor.DEFAULT_REGION;
      authRegionAccessor.saveRegion(regionInStore);
    }
    return regionInStore;
  });

  const saveRegion = (item: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const selectedRegion = item.detail.id;
    authRegionAccessor.saveRegion(selectedRegion);
    setRegion(selectedRegion);
  };

  return (
    <div>
      <ButtonDropdown data-testid="auth-region-selector" items={AUTH_REGION_DROPDOWN_ITEMS} onItemClick={saveRegion}>
        {region}
      </ButtonDropdown>
    </div>
  );
};

export default AuthRegionSelector;
