import styled from 'styled-components';
import * as awsui from '@amzn/awsui-design-tokens';

export const StyledHomePage = styled.div`
  .home__header {
    background-color: ${awsui.colorBackgroundHomeHeader};
    padding: 4rem 0 3rem 0;
    width: 100%;
  }

  .home__category,
  .home__header-title,
  .home__header-title p {
    font-weight: lighter;
  }

  .home__category,
  .home__header-title {
    color: ${awsui.colorTextHomeHeaderDefault};
  }

  .home__header-title p {
    color: ${awsui.colorTextHomeHeaderSecondary};
  }

  .home__category {
    text-transform: uppercase;
  }

  @media (min-width: 992px) {
    .home__sidebar {
      margin-top: -6rem;
    }
  }

  .table-row {
    border-bottom: 1px solid ${awsui.colorBackgroundControlDisabled};
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .table-row:first-child {
    padding-top: 0;
  }

  .table-row:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;
