import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '@amzn/awsui-components-react-v3/polaris/link';

import { StyledHomePage } from './styles';

const Header = () => {
  const { t } = useTranslation('common');
  return (
    <div className="home__header">
      <div className="col-xxs-12">
        <div className="awsui-row">
          <div className="home__header-title awsui-util-ml-xxl">
            <div className="awsui-text-large">
              <div>{t('vibe_amazon_vibe')}</div>
            </div>
            <p className="awsui-util-font-size-3">{t('vibe_capacity_planning_suite')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const MainColumn = () => {
  const { t } = useTranslation('common');
  return (
    <div className="col-xxxs-12 col-s-8">
      <h1>{t('homePage_about_vibe')}</h1>
      <div className="awsui-util-container">
        <div>
          {t('homePage_subtitle_1')} <br />
          {t('homePage_subtitle_2')}
        </div>
      </div>
    </div>
  );
};

const RightColumn = () => {
  return (
    <div className="col-s-4 col-xxxs-12">
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Resources</h2>
        </div>
        <div>
          <div className="table-row">
            <Link href="https://w.amazon.com/bin/view/CPTech/Vibe/VibeAccessSOP" external target="blank">
              Vibe access issues
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Home() {
  return (
    <StyledHomePage className="awsui-util-container awsui-grid">
      <div className="awsui-row">
        <Header />
      </div>
      <div className="awsui-row">
        <MainColumn />
        <RightColumn />
      </div>
    </StyledHomePage>
  );
}
