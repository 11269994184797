import { getRtwoPagePaths } from '@wfo/rtwo-components/dist/modules/nav-items';
import { getScheduleManagementPagePaths } from '@wfo/schedule-management-components/dist/modules/nav-items';

import { RTWO_BASE_PATH, SCHEDULING_BASE_PATH } from '../constants/page-paths';
import { pathToRegexp } from 'path-to-regexp';

const ALLOWED_LIST_PATHS = [
  ...getRtwoPagePaths(RTWO_BASE_PATH),
  ...getScheduleManagementPagePaths(SCHEDULING_BASE_PATH)
];

// Include paths to include CN in region selector
const CN_ENABLED_PATHS: string[] = [...getScheduleManagementPagePaths(SCHEDULING_BASE_PATH)];

/**
 * Tells if the current path is one of the allowed list paths.
 *
 * @param {*} currentPath the current path
 * @param {*} allowedListPath a allowed list path
 */
const isAllowedList = (currentPath: string, allowedListPath: string) => {
  let match = pathToRegexp(allowedListPath).exec(currentPath);
  return match !== null && match !== undefined;
};

export const isRegionSelectorEnabledForPath = (currentPath: string) => {
  let enabled = false;
  ALLOWED_LIST_PATHS.forEach(allowedListPath => (enabled = enabled || isAllowedList(currentPath, allowedListPath)));

  return enabled;
};

export const isCnRegionEnabledForPath = (currentPath: string) => {
  return CN_ENABLED_PATHS.reduce((prev, cur) => {
    return prev || isAllowedList(currentPath, cur);
  }, false);
};
