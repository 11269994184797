import React, { useState } from 'react';
import { Button } from '@amzn/awsui-components-react-v3';
import { getCurrentHref } from '../../utilities/url-helper';
import { StyledLoginPage } from './styles';
import { AuthRegionAccessor } from '../auth-region-selector/auth-region-accessor';
import AuthRegionSelector from '../auth-region-selector/AuthRegionSelector';
import { AppConfig } from '../../context/authService';
import { useTranslation } from 'react-i18next';

const LoginHeader = () => {
  const { t } = useTranslation('common');

  return (
    <div className="login__header">
      <div className="col-xxs-12">
        <div className="awsui-row">
          <div className="login__header-title awsui-util-ml-xxl">
            <div className="awsui-text-large">
              <div>{t('vibe_amazon_vibe')}</div>
            </div>
            <p className="awsui-util-font-size-3">{t('vibe_capacity_planning_suite')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoginButton: React.FC<{ apiName: string }> = ({ apiName }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('common');
  return (
    /**
     * TODO: https://issues.amazon.com/issues/Optimus-7553
     */
    <form action={apiName} method="post" onSubmit={() => setLoading(true)}>
      <input type="hidden" name="identityProviderName" value="CS_GLOBAL_IDENTITY_SERVICE" />
      <input type="hidden" name="redirectUri" value={getCurrentHref()} />
      <Button loading={loading} variant="primary" formAction="submit">
        {t('loginPage_login')}
      </Button>
    </form>
  );
};

const LoginColumn: React.FC<{ configuration: AppConfig | null }> = ({ configuration }) => {
  const { t } = useTranslation('common');
  if (configuration == null) {
    return <div className="error-note col-xxxs-12 col-xs-8 offset-xs-2">{t('loginPage_error_message')}</div>;
  }
  const apiName = configuration.AUTHENTICATE_API_URL;
  const authRegionAccessor = new AuthRegionAccessor(configuration);
  return (
    <div className="login__column-parent login__column-child login-section">
      <div className="login__column-child awsui-util-font-size-2">{t('loginPage_use_csc_credentials_from')}</div>
      <div className="login__column-child">
        <AuthRegionSelector authRegionAccessor={authRegionAccessor} />
      </div>
      <div className="login__column-child">
        <LoginButton apiName={apiName} />
      </div>
    </div>
  );
};

const Login: React.FC<{ configuration: AppConfig | null }> = ({ configuration }) => {
  return (
    <StyledLoginPage className="awsui-util-container awsui-grid">
      <div className="awsui-row">
        <LoginHeader />
      </div>

      <div className="awsui-row login__column-parent">
        <LoginColumn configuration={configuration} />
      </div>
    </StyledLoginPage>
  );
};

export default Login;
