import React from 'react';
import { HelpSelectorText } from '../styles';
import { useTranslation } from 'react-i18next';

export const HelpButtonContent: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <>
      <HelpSelectorText>{t('vibe_help')}</HelpSelectorText>
    </>
  );
};
