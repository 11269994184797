import * as React from 'react';
import { ButtonDropdown, ButtonDropdownProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react-v3';
import { SUPPORTED_REGIONS, RegionAccessor } from './region-accessor';
import { useTranslation } from 'react-i18next';

/**
 * Transforms the supported regions list to drop down input.
 */
const toButtonDropdownItems = (supportedRegionsList: string[]) => {
  return supportedRegionsList.map(region => {
    return { text: region, id: region, disabled: false };
  });
};

interface RegionSelectorProps {
  regionAccessor: RegionAccessor;
  showCnRegion?: boolean;
}
/**
 * This component exposes a dropdown interface to select and save the region.
 */
const RegionSelector: React.FC<RegionSelectorProps> = ({ regionAccessor, showCnRegion = false }) => {
  const saveRegion = (item: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    let selectedRegion = item.detail.id;
    regionAccessor.saveRegion(selectedRegion);
  };

  let regions = SUPPORTED_REGIONS;
  if (showCnRegion && regions.indexOf('CN') === -1) {
    regions.push('CN');
  } else if (!showCnRegion && regions.indexOf('CN') !== -1) {
    regions.splice(regions.indexOf('CN'), 1);
  }

  let items = toButtonDropdownItems(regions);

  const { t } = useTranslation('common');

  return (
    <div>
      <ButtonDropdown data-testid="region-selector" items={items} onItemClick={saveRegion}>
        {t('vibe_region') + ': ' + regionAccessor.getRegion()}
      </ButtonDropdown>
    </div>
  );
};

export default RegionSelector;
