import React from 'react';
import { Icon, Popover, Link } from '@amzn/awsui-components-react-v3';
import { HelpButtonContent } from './HelpButton';
import { HelpSelectorIcon } from '../styles';
import { useTranslation } from 'react-i18next';

const HelpSelectorContent = () => {
  const { t } = useTranslation('common');

  return (
    <Link external href="https://w.amazon.com/bin/view/CPTech/Vibe/VibeAccessSOP">
      {t('vibe_access_issues')}
    </Link>
  );
};

export const HelpSelector = () => {
  return (
    <Popover
      dismissButton={false}
      fixedWidth={true}
      position="bottom"
      size="small"
      triggerType="text"
      content={<HelpSelectorContent />}
    >
      <HelpButtonContent data-testId="helpButton" />
      <HelpSelectorIcon>
        <Icon name="caret-down-filled" size="normal" />
      </HelpSelectorIcon>
    </Popover>
  );
};
